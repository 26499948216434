var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"tWr-9C5uXrkYWVpyqm3TM"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_SAMPLE_RATE = process.env.SENTRY_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE;
const enabled = Number(process.env.NEXT_PUBLIC_SENTRY_ENABLED) || 0;

if (enabled) {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://28a9cb5f11e9104b3f16247b517b6bcf@o281742.ingest.sentry.io/4506488129585152',
    tracesSampleRate: Number(SENTRY_SAMPLE_RATE) || 0.02,
    integrations: [new Sentry.BrowserTracing()],
    ignoreErrors: [
      'top.GLOBALS',
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      'Object.keys called on non-object',
      'fb_xd_fragment',
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      'conduitPage',
      `Request failed with status code 404`,
      `Network Error`,
      'Failed to fetch',
      'Script error.',
      'destroySlots is not a function',
      'Load failed',
      'OneSignal: The OneSignal web SDK can only be initialized once. Extra initializations are ignored. Please remove calls initializing the SDK more than once.', // remover apos migração completa do site
      'timeout exceeded',
      'Request aborted',
      'Invariant: attempted to hard navigate to the same URL',
      'getPercent is not defined',
      `t.destroySlots is not a function. (In 't.destroySlots()', 't.destroySlots' is undefined)`,
      'e is undefined',
      'Request aborted',
      `Failed to execute 'getBattery' on 'Navigator': Illegal invocation`,
      `timeout of 2000ms exceeded`,
      `InvalidStateError: Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.`,
      `TypeError: Cannot read properties of undefined (reading 'log')`,
      `TypeError: Cannot read properties of undefined (reading 'getAttribute')`,
      `UnhandledRejection: Non-Error promise rejection captured with value: undefined`,
      `Cannot read properties of null (reading 'parent')`,
      `null is not an object (evaluating 't.__AMP_TOP')`,
      `Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
      `Cannot read property 'getInitialProps' of undefined`,
      `Unexpected token '<'`,
      `ResizeObserver loop limit exceeded`,
      ` Cannot read property 'getInitialProps' of undefined`,
      `Maximum call stack size exceeded`,
      `Blocked a restricted frame with origin "https://canaltech.com.br" from accessing another frame.`,
      `globalThis is not defined`,
      `pagead/managed/js/gpt`,
      `Cannot read properties of null (reading 'getElementsByTagName')`,
      `Failed to execute 'postMessage' on 'Window': Invalid target origin 'null' in a call to 'postMessage'.`,
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}
